import { CommandItem } from "../../../types/commandItem";

export const executeCommand = (command: CommandItem): Promise<string> => {
  return new Promise((resolve) => {
    const messageListener = (event: MessageEvent) => {
      // If the message is a response to our command, resolve the promise
      if (event.data.command && event.data.command.gds_command === command.gds_command) {
        window.removeEventListener('message', messageListener);
        resolve(event.data.response);
      }
    };
    window.addEventListener('message', messageListener);
    window.parent.postMessage({source: "ticker-amadeus", type: 'executeCommand', command }, '*');
  });
};
