import {
    TERMINALS_FETCHING_DATA,
    TERMINALS_DATA_RECEIVED_SUCCESS, SET_CURRENT_TERMINAL,
} from '../constants';
import {SetCurrentTerminalPayload, TerminalsDataReceivedSuccessPayload, TerminalsFetchingRequest} from "./types";

export const terminalsFetchData = (payload: TerminalsFetchingRequest) => ({
    type: TERMINALS_FETCHING_DATA,
    payload: { ...payload }
});

export const terminalsDataReceivedSuccess = (payload: TerminalsDataReceivedSuccessPayload) => ({
    type: TERMINALS_DATA_RECEIVED_SUCCESS,
    payload: { ...payload }
});

export const terminalsDataReceivedError = (payload: TerminalsDataReceivedSuccessPayload) => ({
    type: TERMINALS_DATA_RECEIVED_SUCCESS,
    payload: { ...payload }
});

export const setCurrentTerminal = (payload: SetCurrentTerminalPayload) => ({
    type: SET_CURRENT_TERMINAL,
    payload: { ...payload }
});
