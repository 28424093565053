import React, {useEffect, Suspense} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AppLayout from "./layout/app_layout";
import Appsignal from "@appsignal/javascript";
import {ErrorBoundary} from "@appsignal/react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./types/rootState";
import {authTokenGet} from "./redux/auth/actions";
import {API_LOGIN, API_PASSWORD, APPSIGNAL_LOGS_KEY, ROOT_ROUTE} from "./config";

const CommandsView = React.lazy(() => import('./views/commandsView'));
const TerminalsView = React.lazy(() => import('./views/terminalsView'));

const App: React.FC = () => {
    const dispatch = useDispatch();
    const authToken = useSelector((state: RootState) => state.auth.authToken);
    const authTokenLoading = useSelector((state: RootState) => state.auth.loading);

    useEffect(() => {
        if (!authToken && !authTokenLoading) {
            dispatch(authTokenGet({login: API_LOGIN, password: API_PASSWORD}));
        }
    }, [authToken, authTokenLoading, dispatch]);

    const appsignal = new Appsignal({key: APPSIGNAL_LOGS_KEY});

    return (
        <ErrorBoundary instance={appsignal}>
            <Suspense fallback={<div>Loading...</div>}>
                <AppLayout>
                    <Router>
                        <Routes>
                            <Route path="*" element={<TerminalsView/>}/>
                            <Route path={ROOT_ROUTE} element={<TerminalsView/>}/>
                            <Route path={`${ROOT_ROUTE}/terminals`} element={<TerminalsView/>}/>
                            <Route path={`${ROOT_ROUTE}/commands-monitor`} element={<CommandsView/>}/>
                        </Routes>
                    </Router>
                </AppLayout>
            </Suspense>
        </ErrorBoundary>
    );
}

export default App;
