import {
    GET_AUTH_TOKEN,
    GET_AUTH_TOKEN_SUCCESS,
    GET_AUTH_TOKEN_ERROR
} from '../constants';

import {Action} from "../../types/action";

const INIT_STATE = {
    authToken: null,
    loading: false,
    error: false
}

const authReducer = (state = INIT_STATE, action: Action) => {
    switch (action.type) {
        case GET_AUTH_TOKEN:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_AUTH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                authToken: action.payload.token,
                error: false
            };

        case GET_AUTH_TOKEN_ERROR:
            return {
                ...state,
                loading: false,
                authToken: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}

export default authReducer;