import {
    FETCH_COMMANDS,
    FETCH_COMMANDS_SUCCESS,
    INIT_PING_COMMANDS,
    RUN_PING_COMMANDS,
    SET_DOM_COMMANDS_RESULT,
    SET_DOM_COMMANDS_RESULT_SUCCESS
} from '../constants';
import {Action} from "../../types/action";

const initialState = {
    commandsList: [],
    pingNeeded: false,
    loading: false,
    connectedToSse: false
};

const commandReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case FETCH_COMMANDS:
            return {
                ...state,
                loading: true,
                connectedToSse: true
            };
        case FETCH_COMMANDS_SUCCESS:
            return {
                ...state,
                commandsList: action.payload.commands,
                loading: false
            };
        case SET_DOM_COMMANDS_RESULT:
            return {
                ...state,
                loading: true
            };
        case SET_DOM_COMMANDS_RESULT_SUCCESS:
            return {
                ...initialState,
                connectedToSse: true
            };
        case RUN_PING_COMMANDS:
            return {
                ...state,
                commandsList: action.payload.commands,
                loading: false
            };
        case INIT_PING_COMMANDS:
            return {
                ...state,
                pingNeeded: true
            };
        default:
            return state;
    }
};

export default commandReducer;