import {CommandItem} from "../../../types/commandItem";
import Logger from '../../appsignal'
import {resultObserver} from "./resultObserver";
import {TERMINAL_EL_SELECTOR} from "./constants";

export const executeCommand = (command: CommandItem): Promise<string> => {
    return new Promise((resolve, reject) => {
        const logger = new Logger();
        const sabrePage: Document = window.parent.document;
        const inputEl = sabrePage.querySelector("#cmdln");
        const buttonEl = sabrePage.querySelector(".send-button");
        const commandTerminalEl = sabrePage.querySelector(TERMINAL_EL_SELECTOR);

        if (!buttonEl || !inputEl || !commandTerminalEl) {
            const body = sabrePage.querySelector('body');
            logger.send(`command ${command.gds_command} sabre result fail`, {command: command,
                command_id: command.id, body: body, error: 'not found a html el', buttonEl: buttonEl, inputEl: inputEl,
                commandTerminalEl: commandTerminalEl}, 'execute_command');
            return reject('not found a html el: buttonEl || inputEl || commandTerminalEl');
        }

        // @ts-expect-error input has value
        inputEl.value = command.gds_command;
        setTimeout(() => {
            // @ts-expect-error button has click
            buttonEl.click();
        }, 10); // add delay to make sure the command is inputted

        resultObserver(command)
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                logger.send(`command ${command.gds_command} sabre result fail`, {command: command, command_id: command.id, error: error}, 'execute_command');
                return reject(error);
            });
    });
};