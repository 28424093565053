import {CommandItem} from "../../../../types/commandItem";
import {TERMINAL_EL_SELECTOR} from "../constants";

// parse general text format result from Sabre terminal
// and set dialog command 'N' and 'Q' if needed
export const generalResult = (responseEl: Element, command: CommandItem): Promise<string> => {
    return new Promise((resolve, reject) => {
        console.log('generalResult check', command);
        const lines = responseEl.querySelectorAll('.dn-line');
        const result = Array.from(lines).map(textLine => textLine.textContent).join("\n");
        let timeout = 0;

        setDialogCommand(responseEl)
            .then(setDialogCommand => {
                if (setDialogCommand) {
                    timeout = 500;
                }
                if (result) {
                    setTimeout(() => {
                        const labelPassengers = responseEl.querySelectorAll('.pull-left.passenger-type-label');
                        const labelTotal = responseEl.querySelectorAll('.pull-right.total-label');
                        if (labelPassengers.length > 0 && labelTotal.length > 0) {
                            return reject('passengers table found');
                        }

                        return resolve(result);
                    }, timeout); // add delay if command 'N' or 'Q' was set
                } else {
                    return reject('general result not found');
                }
            });
    });
}

const setDialogCommand = (responseEl: Element): Promise<boolean> => {
    return new Promise((resolve) => {
        let setDialogCommand = false;
        const sabrePage: Document = window.parent.document;
        const inputEl1 = dialogInput(responseEl)

        if (!inputEl1) {
            return resolve(setDialogCommand);
        }

        setDialogCommand = true;
        // @ts-expect-error input has value
        inputEl1.value = 'N';
        // @ts-expect-error button has click
        sabrePage.querySelector(".send-button").click();

        observeDialogResult().then((inputEl) => {
            if (inputEl) {
                // @ts-expect-error input has value
                inputEl.value = 'Q';
                // @ts-expect-error button has click
                sabrePage.querySelector(".send-button").click();
                return resolve(setDialogCommand);
            } else {
                return resolve(setDialogCommand);
            }
        });
    });
}

const dialogInput = (responseEl: Element): Element | null => {
    return responseEl.querySelector('input.form-control') || null;
}

const observeDialogResult = (): Promise<Element | null> => {
    return new Promise((resolve) => {
        const sabrePage: Document = window.parent.document;
        const commandTerminalEl = sabrePage.querySelector(TERMINAL_EL_SELECTOR);
        const resultObserver = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    observer.disconnect();
                    const sabrePage: Document = window.parent.document;
                    const responseElArr = sabrePage.querySelectorAll('.dn-response-line');
                    const loaderEl = sabrePage.querySelector('.load-container');
                    if (responseElArr.length <= 0 || loaderEl) {
                        continue;
                    }
                    const responseEl = responseElArr[responseElArr.length - 1];
                    return resolve(dialogInput(responseEl));
                }
            }
        });

        if (!commandTerminalEl) {
            console.error('not found a html el "commandTerminalEl" in "observeDialogResult"');
            return;
        }
        resultObserver.observe(commandTerminalEl, {childList: true, subtree: true});
    });
}