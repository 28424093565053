import React, {ReactNode} from 'react';
import {Container, Row, Col} from 'reactstrap';
import './AppLayout.scss';
import {APP_VERSION, REACT_APP_ENV_NAME} from "../config";

interface AppLayoutProps {
    children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({children}) => {
    return (
        <div className="app-layout">
            <header className="app-header text-center text-dark pt-2">
                <h1 className="text-primary">Olexa</h1>
            </header>
            <Container style={{flex: '1'}}>
                <Row>
                    <Col xs="12" className="p-4">
                        {children}
                    </Col>
                </Row>
            </Container>
            <footer className="text-center bg-dark text-white pt-3">
                <p>Olexa on {REACT_APP_ENV_NAME} environment v{APP_VERSION}</p>
            </footer>
        </div>
    );
};

export default AppLayout;