import {TERMINAL_EL_SELECTOR} from "./constants";
import {CommandItem} from "../../../types/commandItem";
import {pricingTableResult} from "./resultParsing/pricingTableResult";
import {generalResult} from "./resultParsing/generalResult";
import Logger from '../../appsignal'

// observe result appeared in Sabre terminal

const observeResultLine = (command: CommandItem, commandTerminalEl: Element): Promise<Element> => {
    return new Promise((resolve) => {
        const resultObserver = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (!(mutation.type === 'childList')) continue;
                for (const node of Array.from(mutation.addedNodes)) {
                    const element: Element = node as Element;
                    if (!element || !(element.className?.includes('dn-response-line'))) continue;
                    observer.disconnect();
                    return resolve(element);
                }
            }
        });

        resultObserver.observe(commandTerminalEl, {childList: true, subtree: true});
    });
}

export const resultObserver = (command: CommandItem): Promise<string> => {
    const logger = new Logger();
    const sabrePage: Document = window.parent.document;
    const commandTerminalEl = sabrePage.querySelector(TERMINAL_EL_SELECTOR);
    if (!commandTerminalEl) {
        logger.send(`command ${command.gds_command} sabre result fail`, {command: command, command_id: command.id,
            error: 'not found a html el "commandTerminalEl" in "resultObserver"'}, 'execute_command');
        return Promise.reject('not found a html el "commandTerminalEl" in "resultObserver"');
    }
    return new Promise((resolve, reject) => {
        observeResultLine(command, commandTerminalEl).then(responseEl => {
            setTimeout(() => {
                generalResult(responseEl, command).then(result => {
                    return resolve(result);
                }).catch(error => {
                    console.log(error);
                    const result = pricingTableResult(responseEl);
                    if (result) {
                        return resolve(result);
                    } else {
                        return reject('Parsing sabre page error - not found result');
                    }
                });
            }, 500);
        });
    });
}