import {
    TERMINALS_FETCHING_DATA,
    TERMINALS_DATA_RECEIVED_SUCCESS,
    SET_CURRENT_TERMINAL
} from '../constants';
import {Action} from "../../types/action";

const INIT_STATE = {
    terminalsList: [],
    loading: false,
    currentTerminal: null,
};

const reducer = (state = INIT_STATE, action: Action) => {
    switch (action.type) {
        case TERMINALS_FETCHING_DATA:
            return {
                ...state,
                loading: true,
            };
        case TERMINALS_DATA_RECEIVED_SUCCESS:
            return {
                ...state,
                terminalsList: action.payload.terminals,
                loading: false,
            };
        case SET_CURRENT_TERMINAL:
            return {
                ...state,
                currentTerminal: action.payload.terminal,
            };
        default:
            return { ...state };
    }
};

export default reducer;