import {
    FETCH_COMMANDS,
    FETCH_COMMANDS_SUCCESS,
    INIT_PING_COMMANDS,
    RUN_PING_COMMANDS,
    SET_DOM_COMMANDS_RESULT,
    SET_DOM_COMMANDS_RESULT_ERROR,
    SET_DOM_COMMANDS_RESULT_SUCCESS
} from '../constants';
import {FetchCommandsPayload, FetchCommandsSuccessPayload} from "./types";
import {SetDomCommandsResultPayload} from "./types";

export const fetchCommands = (payload: FetchCommandsPayload) => ({
    type: FETCH_COMMANDS,
    payload: {...payload}
});

export const fetchCommandsSuccess = (payload: FetchCommandsSuccessPayload) => ({
    type: FETCH_COMMANDS_SUCCESS,
    payload: {...payload}
});

export const setDomCommandsResult = (payload: SetDomCommandsResultPayload) => ({
    type: SET_DOM_COMMANDS_RESULT,
    payload: {...payload}
});

export const setDomCommandsResultSuccess = () => ({
    type: SET_DOM_COMMANDS_RESULT_SUCCESS,
    payload: {}
});

export const setDomCommandsResultError = () => ({
    type: SET_DOM_COMMANDS_RESULT_ERROR,
    payload: {}
});

export const runPingCommands = (payload: FetchCommandsSuccessPayload) => ({
    type: RUN_PING_COMMANDS,
    payload: {...payload}
});

export const initPingCommands = () => ({
    type: INIT_PING_COMMANDS,
    payload: {}
});
