import {APP_VERSION, APPSIGNAL_LOGS_KEY, REACT_APP_ENV_NAME} from "../config";

export default class {
    async send(message, attributes = {}, group = 'default') {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            attributes['version'] = APP_VERSION;
            attributes['env'] = REACT_APP_ENV_NAME;

            // stringify all attributes
            Object.keys(attributes).forEach(key => {
                if (typeof attributes[key] !== 'string') {
                    attributes[key] = JSON.stringify(attributes[key]);
                }
            });

            const raw = JSON.stringify({
                "timestamp": new Date().toISOString(),
                "group": group,
                "severity": "info",
                "message": message,
                "hostname": "olexa-runner",
                "attributes": attributes
            });

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
                mode: 'no-cors'
            };

            const url = `https://appsignal-endpoint.net/logs/json?api_key=${APPSIGNAL_LOGS_KEY}`;
            await fetch(url, requestOptions);
        } catch (error) {
            console.log('logging exception', error);
        }
    }
}