// TODO rename GDS DOM because not all commands running via DOM. For galileo we using galileo requests
// new name - GDS Commands, for example

import {
    CALL_GDS_DOM,
    CALL_GDS_DOM_ERROR,
    CALL_GDS_DOM_SUCCESS,
    EXECUTE_GDS_COMMANDS
} from "../constants";
import {
    CallGdsDomErrorPayload,
    CallGdsDomPayload,
    CallGdsDomSuccessPayload,
    ExecuteGdsCommandsPayload
} from "./types";

export const executeGdsCommands = (payload: ExecuteGdsCommandsPayload) => ({
    type: EXECUTE_GDS_COMMANDS,
    payload: {...payload}
});

export const callGdsDom = (payload: CallGdsDomPayload) => ({
    type: CALL_GDS_DOM,
    payload: {...payload}
});

export const callGdsDomSuccess = (payload: CallGdsDomSuccessPayload) => ({
    type: CALL_GDS_DOM_SUCCESS,
    payload: {...payload}
});

export const callGdsDomError = (payload: CallGdsDomErrorPayload) => ({
    type: CALL_GDS_DOM_ERROR,
    payload: {...payload}
});

