import {all} from 'redux-saga/effects';
import terminalsSagas from './terminals/saga';
import commandsSagas from './commands/saga';
import gdsDomSagas from './gds_dom/saga';
import authSagas from './auth/saga';

export default function* rootSaga() {
    yield all([
        terminalsSagas(),
        commandsSagas(),
        gdsDomSagas(),
        authSagas()
    ]);
}