import { combineReducers } from "redux";
import terminals from "./terminals/reducer";
import commands from "./commands/reducer";
import gdsDom from "./gds_dom/reducer";
import auth from "./auth/reducer";

const reducers = combineReducers({
    terminals,
    commands,
    gdsDom,
    auth
});

export default reducers;
