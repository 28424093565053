import {takeLatest, put, call} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';
import {authTokenSuccess} from "./actions";
import {GET_AUTH_TOKEN} from "../constants";
import {API_BASE_URL} from '../../config';
import {SetAuthTokenRequest, SetAuthTokenResponseSuccess} from "./types";

function* getAuthTokenSaga(action: { type: string, payload: SetAuthTokenRequest }) {
    const response: AxiosResponse<SetAuthTokenResponseSuccess> = yield call(
        axios.post,
        `${API_BASE_URL}/user/v1/session/runner/login`,
        {
            login: action.payload.login,
            password: action.payload.password
        },
        {
            headers: {
                'Accept': 'application/json'
            }
        }
    );

    yield put(authTokenSuccess(response.data));
}

function* rootSaga() {
    yield takeLatest(GET_AUTH_TOKEN, getAuthTokenSaga);
}

export default rootSaga;

