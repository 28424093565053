import { put, call, takeLatest } from 'redux-saga/effects';
import {CALL_GDS_DOM} from '../constants';
import { executeCommand as executeSabreCommand } from "../../lib/gds_dom/sabre/executeCommand";
import { executeCommand as executeGalileoCommand } from "../../lib/gds_dom/galileo/executeCommand";
import { executeCommand as executeAmadeusCommand } from "../../lib/gds_dom/amadeus/executeCommand";
import {callGdsDomSuccess} from "./actions";
import {CallGdsDomPayload} from "./types";

function defineExecuteCommand(gds: string) {
    switch (gds) {
        case 'galileo':
            return executeGalileoCommand;
        case 'amadeus':
            return executeAmadeusCommand;
        case 'sabre':
            return executeSabreCommand;
        default:
            throw new Error(`Unknown GDS: ${gds}`);
    }
}

// todo rename workerSaga everywhere
function* workerSaga(action: {type: string, payload: CallGdsDomPayload}) {
    const executeCommand = defineExecuteCommand(action.payload.gds)
    const response: string = yield call(executeCommand, action.payload.command);
    yield put(callGdsDomSuccess({response: response}));
}

function* rootSaga() {
    yield takeLatest(CALL_GDS_DOM, workerSaga);
}

export default rootSaga;
