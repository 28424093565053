

// TERMINALS
export const TERMINALS_DATA_RECEIVED_SUCCESS = 'TERMINALS_DATA_RECEIVED_SUCCESS';
export const TERMINALS_FETCHING_DATA = 'TERMINALS_FETCHING_DATA';
export const SET_CURRENT_TERMINAL = 'SET_CURRENT_TERMINAL';

// COMMANDS
export const FETCH_COMMANDS = 'FETCH_COMMANDS';
export const FETCH_COMMANDS_SUCCESS = 'FETCH_COMMANDS_SUCCESS';
export const RUN_PING_COMMANDS = 'RUN_PING_COMMANDS';
export const INIT_PING_COMMANDS = 'INIT_PING_COMMANDS';


// TODO refactoring name standard for constants


export const EXECUTE_GDS_COMMANDS = 'EXECUTE_GDS_COMMANDS';

export const CALL_GDS_DOM = 'CALL_GDS_DOM';

export const CALL_GDS_DOM_SUCCESS = 'CALL_GDS_DOM_SUCCESS';

export const CALL_GDS_DOM_ERROR = 'CALL_GDS_DOM_ERROR';

export const SET_DOM_COMMANDS_RESULT = 'SET_DOM_COMMANDS_RESULT';
export const SET_DOM_COMMANDS_RESULT_SUCCESS = 'SET_DOM_COMMANDS_RESULT_SUCCESS';
export const SET_DOM_COMMANDS_RESULT_ERROR = 'SET_DOM_COMMANDS_RESULT_ERROR';

// AUTH - auth runner on backend
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const GET_AUTH_TOKEN_SUCCESS = 'GET_AUTH_TOKEN_SUCCESS';
export const GET_AUTH_TOKEN_ERROR = 'GET_AUTH_TOKEN_ERROR';
