import {takeLatest, put, call} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';
import {terminalsDataReceivedSuccess} from "./actions";
import {TERMINALS_FETCHING_DATA} from "../constants";
import {Terminal} from "../../types/terminal";
import { API_BASE_URL } from '../../config';
import {TerminalsFetchingRequest} from "./types";

interface TerminalsResponse {
    terminals: Terminal[];
}

function* workerSaga(action: { type: string, payload: TerminalsFetchingRequest }) {
    const response: AxiosResponse<TerminalsResponse> = yield call(
        axios.get,
        `${API_BASE_URL}/command_processing/v1/runner/terminals?gds=${action.payload.gds}`,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': action.payload.authToken
            }
        }
    );
    // TODO check is success, handle Failure action
    yield put(terminalsDataReceivedSuccess(response.data));
}

function* rootSaga() {
    yield takeLatest(TERMINALS_FETCHING_DATA, workerSaga);
}

export default rootSaga;