import { put, call, takeLatest, take, SagaReturnType } from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';
import {FETCH_COMMANDS, SET_DOM_COMMANDS_RESULT} from '../constants';
import {fetchCommandsSuccess} from './actions';
import {CommandItem} from "../../types/commandItem";
import {FetchCommandsPayload} from "./types";
import {SetDomCommandsResultPayload} from "./types";
import {DomCommandResult} from "../../types/rootState";
import {setDomCommandsResultSuccess} from "./actions";
import {eventChannel} from "redux-saga";
import {API_BASE_URL} from '../../config';
import Logger from "../../lib/appsignal";

function fetchCommandsChannel(payload: FetchCommandsPayload) {
    return eventChannel(emitter => {
        const terminalCode = payload.terminal_code;
        const authToken = payload.authToken;
        const eventSource = new EventSource(
            `${API_BASE_URL}/command_processing/v1/runner/command_operations/command_items/queue/live/?terminal_code=${terminalCode}&auth_token=${authToken}`
        );

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.commands && data.commands.length > 0) {
                const logger = new Logger();
                logger.send(`fetched commands emit order id: ${data?.order_id}`, {data}, 'execute_command');
                emitter(data);
            }
        };

        eventSource.onerror = (error) => {
            const logger = new Logger();
            logger.send('fetchCommandsChannel sse error', {}, 'see');
            console.error('EventSource failed:', error, 'error: ', eventSource.readyState);
            emitter('EventSource failed');
        };

        return () => {
            eventSource.close();
        };
    });
}

function* fetchCommandsSaga(action: {type: string, payload: FetchCommandsPayload}) {
    const channel: SagaReturnType<typeof fetchCommandsChannel> = yield call(fetchCommandsChannel, action.payload);

    while (true) {
        const event: {commands: CommandItem[]} = yield take(channel);
        const commands = event.commands;
        if (commands && commands.length > 0) {
            yield put(fetchCommandsSuccess({commands: commands}));
        }
    }
}

function* setDomCommandsResultSaga(action: {type: string, payload: SetDomCommandsResultPayload}) {
    const commandItems: {id: number, result: string}[] = [];

    action.payload.result.forEach((result: DomCommandResult) => {
        commandItems.push({id: result.command.id, result: result.response})
    });

    try {
        const response: AxiosResponse<{success: boolean}> = yield call(
            axios.post,
            `${API_BASE_URL}/command_processing/v1/runner/command_operations/command_items/set_result`,
            {command_items: commandItems},
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': action.payload.authToken
                }
            }
        );

        console.log('setDomCommandsResultSuccess', response); // TODO handle error too
        yield put(setDomCommandsResultSuccess());
    } catch (error) {
        const logger = new Logger();
        logger.send('ERROR set result request', {error: error, command_items: commandItems}, 'item result');
        console.error('setDomCommandsResultError', error);
        yield put(setDomCommandsResultSuccess());
    }
}

function* rootSaga() {
    yield takeLatest(FETCH_COMMANDS, fetchCommandsSaga);
    yield takeLatest(SET_DOM_COMMANDS_RESULT, setDomCommandsResultSaga);
}

export default rootSaga;