import {
    GET_AUTH_TOKEN,
    GET_AUTH_TOKEN_SUCCESS, 
    GET_AUTH_TOKEN_ERROR
} from '../constants';
import {SetAuthTokenRequest, SetAuthTokenResponseError, SetAuthTokenResponseSuccess} from "./types";

export const authTokenGet = (payload: SetAuthTokenRequest) => ({
    type: GET_AUTH_TOKEN,
    payload: {...payload}
});

export const authTokenError = (payload: SetAuthTokenResponseError) => ({
    type: GET_AUTH_TOKEN_ERROR,
    payload: {...payload}
});

export const authTokenSuccess = (payload: SetAuthTokenResponseSuccess) => ({
    type: GET_AUTH_TOKEN_SUCCESS,
    payload: {...payload}
});
