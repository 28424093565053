import {
    CALL_GDS_DOM,
    CALL_GDS_DOM_SUCCESS,
    CALL_GDS_DOM_ERROR,
    EXECUTE_GDS_COMMANDS
} from '../constants';
import {Action} from "../../types/action";

const initialState = {
    currentCommand: null,
    currentCommandIndex: 0,
    currentResult: null,
    currentError: null,
    commandsList: [],
    commandsResults: [],
    loading: false
};

const commandReducer = (state = initialState, action: Action) => {
    const isLastCommand = state.currentCommandIndex >= state.commandsList.length;
    const nextCommand = isLastCommand ? null : state.commandsList[state.currentCommandIndex];
    const nextCommandIndex = isLastCommand ? null : state.currentCommandIndex;
    const currentCommandIndex = nextCommandIndex ? nextCommandIndex + 1 : null;

    switch (action.type) {
        case EXECUTE_GDS_COMMANDS:
            return {
                ...state,
                // @ts-expect-error test
                currentCommand: action.payload.commands[0],
                currentCommandIndex: 1,
                currentResult: null,
                currentError: null,
                commandsList: action.payload.commands,
                commandsResults: []
            };
        case CALL_GDS_DOM:
            return {
                ...state,
                loading: true
            };
        case CALL_GDS_DOM_SUCCESS:
            return {
                ...state,
                commandsResults: [...state.commandsResults, {response: action.payload.response, command: state.currentCommand}],
                currentCommand: nextCommand,
                currentCommandIndex: currentCommandIndex,
                currentResult: action.payload,
                currentError: null,
                loading: false
            };
        case CALL_GDS_DOM_ERROR:
            return {
                ...state,
                commandsResults: [...state.commandsResults, {response: action.payload.response, command: state.currentCommand}],
                currentCommand: nextCommand,
                currentCommandIndex: currentCommandIndex,
                currentResult: null,
                currentError: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default commandReducer;
